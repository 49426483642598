var _a;
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { tokenClaimApi } from "./tokenclaimApi";
import { transactionApi } from "./transactionApi";
export var reducerMapObject = (_a = {},
    _a[tokenClaimApi.reducerPath] = tokenClaimApi.reducer,
    _a[transactionApi.reducerPath] = transactionApi.reducer,
    _a);
export var initStore = function (preloadedState) {
    if (preloadedState === void 0) { preloadedState = {}; }
    var _store = configureStore({
        reducer: reducerMapObject,
        preloadedState: preloadedState,
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: false,
            })
                .concat(tokenClaimApi.middleware)
                .concat(transactionApi.middleware);
        },
    });
    setupListeners(_store.dispatch);
    return _store;
};
export var useStore = function () {
    var _store = initStore();
    return _store;
};
