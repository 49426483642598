extendBorsh();
import { deserializeUnchecked } from "borsh";
import { extendBorsh } from "../borsh";
var TokenClaim = /** @class */ (function () {
    function TokenClaim(args) {
        this.type = args.type;
        this.owner = args.owner;
        this.mint = args.mint;
        this.name = args.name;
        this.state = args.state;
    }
    return TokenClaim;
}());
export { TokenClaim };
export var TOKENCLAIM_SCHEMA = new Map([
    [
        TokenClaim,
        {
            kind: "struct",
            fields: [
                ["type", ["u8", 8]],
                ["owner", "pubkeyAsString"],
                ["mint", "pubkeyAsString"],
                ["name", "string"],
                ["state", "u8"],
            ],
        },
    ],
]);
export var decodeTokenClaim = function (buffer) {
    var parsed = deserializeUnchecked(TOKENCLAIM_SCHEMA, TokenClaim, buffer);
    return parsed;
};
var TokenClaimTarget = /** @class */ (function () {
    function TokenClaimTarget(args) {
        this.type = args.type;
        this.claim = args.claim;
        this.owner = args.owner;
        this.max_amount = args.max_amount.toString();
        this.amount_claimed = args.amount_claimed.toString();
    }
    return TokenClaimTarget;
}());
export { TokenClaimTarget };
export var TOKENCLAIMTARGET_SCHEMA = new Map([
    [
        TokenClaimTarget,
        {
            kind: "struct",
            fields: [
                ["type", ["u8", 8]],
                ["claim", "pubkeyAsString"],
                ["owner", "pubkeyAsString"],
                ["max_amount", "u64"],
                ["amount_claimed", "u64"],
            ],
        },
    ],
]);
export var decodeTokenClaimTarget = function (buffer) {
    var parsed = deserializeUnchecked(TOKENCLAIMTARGET_SCHEMA, TokenClaimTarget, buffer);
    return parsed;
};
