import { __read } from "tslib";
import { useEffect, useState } from "react";
import { useFetchAvailableAmountQuery } from "../store/tokenclaimApi";
import { useLazyClaimTokenQuery } from "../store/transactionApi";
import { useExecutor } from "./useExecutor";
export var useTokenClaimer = function (config, notify, walletId, signAllTransactions, amountToClaim) {
    var _a = useFetchAvailableAmountQuery({
        walletId: walletId,
        claimId: config.quickdropId,
        solanaRpcHost: config.solanaRpcHost,
        apiBaseUrl: config.apiBaseUrl,
        commitment: config.commitment || 'confirmed',
    }, {
        skip: !walletId,
        // pollingInterval: 10000
    }), data = _a.data, isFetching = _a.isFetching, isError = _a.isError;
    var _b = __read(useLazyClaimTokenQuery(), 1), fetch = _b[0];
    var _c = __read(useState("0"), 2), availableAmount = _c[0], setAvailableAmount = _c[1];
    var _d = useExecutor(fetch, {
        walletPublicKey: walletId,
        quickdropId: config.quickdropId,
        amount: amountToClaim || availableAmount,
        apiBaseUrl: config.apiBaseUrl,
    }, notify, walletId, signAllTransactions, config.solanaRpcHost, config.commitment || 'confirmed'), onClick = _d.onClick, isExecuting = _d.isExecuting;
    useEffect(function () {
        setAvailableAmount(data ? (BigInt(data.max) - BigInt(data.claimed)).toString() : "0");
    }, [data]);
    return {
        availableAmount: BigInt(availableAmount),
        totalWhitelistedAmount: BigInt((data === null || data === void 0 ? void 0 : data.max) || "0"),
        claimedAmount: BigInt((data === null || data === void 0 ? void 0 : data.claimed) || "0"),
        onClick: onClick,
        isError: isError,
        isFetching: isFetching,
        isExecuting: isExecuting,
    };
};
