import { __awaiter, __generator } from "tslib";
import { sleep } from "../sleep";
export var awaitTransactionSignatures = function (txid, timeout, connection) { return __awaiter(void 0, void 0, void 0, function () {
    var done, status;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                done = false;
                status = {
                    slot: 0,
                    confirmations: 0,
                    err: null,
                };
                return [4 /*yield*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    setTimeout(function () {
                                        if (done) {
                                            return;
                                        }
                                        done = true;
                                        console.log("Rejected due to timeout");
                                        reject({ timeout: true });
                                    }, timeout);
                                    _a.label = 1;
                                case 1:
                                    if (!!done) return [3 /*break*/, 3];
                                    (function () { return __awaiter(void 0, void 0, void 0, function () {
                                        var signatureStatuses, e_1;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    _a.trys.push([0, 2, , 3]);
                                                    return [4 /*yield*/, connection.getSignatureStatuses([
                                                            txid,
                                                        ])];
                                                case 1:
                                                    signatureStatuses = _a.sent();
                                                    status = signatureStatuses && signatureStatuses.value[0];
                                                    if (!done) {
                                                        if (!status) {
                                                            console.log("Querie null result for", txid, status);
                                                        }
                                                        else if (status.err) {
                                                            console.log("REST error for", txid, status);
                                                            done = true;
                                                            reject(status.err);
                                                        }
                                                        else if (!status.confirmations) {
                                                            console.log("REST no confirmations for", txid, status);
                                                        }
                                                        else {
                                                            console.log("REST confirmation for", txid, status);
                                                            done = true;
                                                            resolve(status);
                                                        }
                                                    }
                                                    return [3 /*break*/, 3];
                                                case 2:
                                                    e_1 = _a.sent();
                                                    if (!done) {
                                                        console.log("REST connection error: txid", txid, e_1);
                                                    }
                                                    return [3 /*break*/, 3];
                                                case 3: return [2 /*return*/];
                                            }
                                        });
                                    }); })();
                                    return [4 /*yield*/, sleep(2000)];
                                case 2:
                                    _a.sent();
                                    return [3 /*break*/, 1];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            case 1:
                // eslint-disable-next-line no-async-promise-executor
                status = _a.sent();
                return [2 /*return*/, status];
        }
    });
}); };
