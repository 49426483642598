import { __awaiter, __generator } from "tslib";
import { PublicKey } from "@solana/web3.js";
export var getClaimPDA = function (tokenClaimProgramId, authority, owner, mint, 
// amount: bigint,
name) { return __awaiter(void 0, void 0, void 0, function () {
    var airdrop;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, PublicKey.findProgramAddress([
                    Buffer.from("tokenclaim"),
                    authority.toBuffer(),
                    owner.toBuffer(),
                    mint.toBuffer(),
                    // toBufferLE(amount, 8),
                    Buffer.from(name),
                ], tokenClaimProgramId)];
            case 1:
                airdrop = (_a.sent())[0];
                return [2 /*return*/, airdrop];
        }
    });
}); };
export var getClaimEventPDA = function (tokenClaimProgramId, claim, destination) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, PublicKey.findProgramAddress([
                    Buffer.from("tokenclaim"),
                    claim.toBuffer(),
                    destination.toBuffer(),
                ], tokenClaimProgramId)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
