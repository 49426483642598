import { __assign, __awaiter, __generator, __read, __values } from "tslib";
import { Connection, Transaction } from "@solana/web3.js";
import { sleep } from "../sleep";
import { awaitTransactionSignatures } from "./awaitSignature";
import { NotifyType } from "./useExecutor";
export var DEFAULT_TIMEOUT = 60000;
var Executor = /** @class */ (function () {
    function Executor(fetch, params, signAllTransactions, notify, solanaRpcHost, commitment) {
        var _this = this;
        this.execute = function () { return __awaiter(_this, void 0, void 0, function () {
            var connection, blockhash, result, transactions, error, signedTransactions, retries, transactionIds_1, _loop_1, this_1, retries_1, retries_1_1, retry, state_1, e_1_1;
            var e_1, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        connection = new Connection(this.solanaRpcHost, this.commitment);
                        return [4 /*yield*/, connection.getLatestBlockhash(this.commitment)];
                    case 1:
                        blockhash = _b.sent();
                        console.log("EXECUTING", { blockhash: blockhash });
                        return [4 /*yield*/, this.fetch(__assign(__assign({}, this.params), { blockhash: blockhash.blockhash }))];
                    case 2:
                        result = _b.sent();
                        console.log({ result: result });
                        transactions = result.data, error = result.error;
                        console.log({ transactions: transactions });
                        if (!transactions) {
                            this.notify("Could not generate transactions", NotifyType.Error);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.signAllTransactions(transactions.map(function (tx) { return Transaction.from(Buffer.from(tx.transaction)); })).catch(function (e) {
                                _this.notify("Signing rejected", NotifyType.Error);
                                return;
                            })];
                    case 3:
                        signedTransactions = _b.sent();
                        retries = [1, 3, 5, 10];
                        if (!signedTransactions) return [3 /*break*/, 11];
                        transactionIds_1 = {};
                        _loop_1 = function (retry) {
                            var errorCount, promises, pos, signedTransactions_1, signedTransactions_1_1, signedTransaction, confirmationPromises, errors_1, _loop_2, _c, _d, _e, idx, txid;
                            var e_2, _f, e_3, _g;
                            return __generator(this, function (_h) {
                                switch (_h.label) {
                                    case 0:
                                        errorCount = 0;
                                        promises = [];
                                        pos = 0;
                                        try {
                                            for (signedTransactions_1 = (e_2 = void 0, __values(signedTransactions)), signedTransactions_1_1 = signedTransactions_1.next(); !signedTransactions_1_1.done; signedTransactions_1_1 = signedTransactions_1.next()) {
                                                signedTransaction = signedTransactions_1_1.value;
                                                if (!(pos in transactionIds_1)) {
                                                    promises.push(this_1.connection
                                                        .sendRawTransaction(signedTransaction.serialize(), {
                                                        preflightCommitment: this_1.commitment
                                                    })
                                                        .then(function (result) {
                                                        transactionIds_1[pos] = result;
                                                    })
                                                        .catch(function (e) {
                                                        errorCount++;
                                                    }));
                                                }
                                                pos++;
                                            }
                                        }
                                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                        finally {
                                            try {
                                                if (signedTransactions_1_1 && !signedTransactions_1_1.done && (_f = signedTransactions_1.return)) _f.call(signedTransactions_1);
                                            }
                                            finally { if (e_2) throw e_2.error; }
                                        }
                                        if (promises.length === 0) {
                                            return [2 /*return*/, "break"];
                                        }
                                        return [4 /*yield*/, Promise.all(promises)];
                                    case 1:
                                        _h.sent();
                                        if (!(errorCount > 0)) return [3 /*break*/, 3];
                                        return [4 /*yield*/, sleep(retry * 500)];
                                    case 2:
                                        _h.sent();
                                        return [3 /*break*/, 5];
                                    case 3:
                                        confirmationPromises = [];
                                        errors_1 = {};
                                        console.log("Awaiting transactions", Object.keys(transactionIds_1));
                                        _loop_2 = function (idx, txid) {
                                            if (txid) {
                                                confirmationPromises.push(awaitTransactionSignatures(txid, DEFAULT_TIMEOUT, this_1.connection)
                                                    .then(function (result) { })
                                                    .catch(function (error) {
                                                    console.log(error);
                                                    errorCount++;
                                                    errors_1[idx] = error;
                                                }));
                                            }
                                        };
                                        try {
                                            for (_c = (e_3 = void 0, __values(Object.keys(transactionIds_1))), _d = _c.next(); !_d.done; _d = _c.next()) {
                                                _e = __read(_d.value, 2), idx = _e[0], txid = _e[1];
                                                _loop_2(idx, txid);
                                            }
                                        }
                                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                                        finally {
                                            try {
                                                if (_d && !_d.done && (_g = _c.return)) _g.call(_c);
                                            }
                                            finally { if (e_3) throw e_3.error; }
                                        }
                                        return [4 /*yield*/, Promise.all(confirmationPromises)];
                                    case 4:
                                        _h.sent();
                                        console.log({ errors: errors_1 });
                                        if (Object.keys(errors_1).length > 0) {
                                            this_1.notify("Transactions failed ".concat(Object.keys(errors_1)
                                                .map(function (idx) { return transactions[+idx].description; })
                                                .join(",")), NotifyType.Error);
                                        }
                                        else {
                                            console.log({ transactions: transactions });
                                            this_1.notify("".concat(Object.values(transactions)
                                                .map(function (tx) { return tx.description; })
                                                .join(","), ": success"), NotifyType.Success);
                                        }
                                        return [2 /*return*/, "break"];
                                    case 5: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _b.label = 4;
                    case 4:
                        _b.trys.push([4, 9, 10, 11]);
                        retries_1 = __values(retries), retries_1_1 = retries_1.next();
                        _b.label = 5;
                    case 5:
                        if (!!retries_1_1.done) return [3 /*break*/, 8];
                        retry = retries_1_1.value;
                        return [5 /*yield**/, _loop_1(retry)];
                    case 6:
                        state_1 = _b.sent();
                        if (state_1 === "break")
                            return [3 /*break*/, 8];
                        _b.label = 7;
                    case 7:
                        retries_1_1 = retries_1.next();
                        return [3 /*break*/, 5];
                    case 8: return [3 /*break*/, 11];
                    case 9:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 11];
                    case 10:
                        try {
                            if (retries_1_1 && !retries_1_1.done && (_a = retries_1.return)) _a.call(retries_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 11: return [2 /*return*/];
                }
            });
        }); };
        this.fetch = fetch;
        this.signAllTransactions = signAllTransactions;
        this.notify = notify;
        this.params = params;
        this.connection = new Connection(solanaRpcHost, commitment);
        this.solanaRpcHost = solanaRpcHost;
        this.commitment = commitment;
    }
    return Executor;
}());
export { Executor };
