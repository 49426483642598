import axios from "axios";
var HttpClient = /** @class */ (function () {
    function HttpClient(baseURL) {
        var _this = this;
        this._initializeResponseInterceptor = function () {
            _this.instance.interceptors.response.use(_this._handleResponse, _this._handleError);
        };
        this._handleResponse = function (_a) {
            var data = _a.data, status = _a.status;
            return ({
                data: data,
                error: undefined,
                status: status,
            });
        };
        this._handleError = function (_a) {
            var response = _a.response, code = _a.code;
            // console.log('Httpclient error', error)
            // Promise.reject(error);
            if (response) {
                var data = response.data, status_1 = response.status;
                // console.log({response})
                return { data: undefined, error: data, status: status_1 };
            }
            else {
                return {
                    data: undefined,
                    error: code,
                    status: 500,
                };
            }
        };
        this.instance = axios.create({
            baseURL: baseURL,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        this.baseUrl = baseURL;
        this._initializeResponseInterceptor();
    }
    HttpClient.prototype.get = function (url, options) {
        return this.instance.get(url, options);
    };
    HttpClient.prototype.post = function (url, data, options) {
        return this.instance.post(url, data, options);
    };
    HttpClient.prototype.delete = function (url, options) {
        return this.instance.delete(url, options);
    };
    HttpClient.prototype.put = function (url, data, options) {
        return this.instance.put(url, data, options);
    };
    HttpClient.prototype.patch = function (url, data, options) {
        return this.instance.patch(url, data, options);
    };
    return HttpClient;
}());
export { HttpClient };
